<template>
    <div class="content pt-0">
        <section id="section-booking" class="section booking customCheckout">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6 offset-md-3" style="color: white">
                        <h1 class="text-center">Bejelentkezés</h1>
                        <p class="text-center">
                            Ha rendelkezel felhasználói fiókkal, kérlek
                            jelentkezz be.
                        </p>
                        <form
                            v-on:submit.prevent="doLogin"
                            class="booking-form checkout-form"
                        >
                            <div class="form-group">
                                <label>Email cím</label>
                                <input
                                    type="email"
                                    v-model="form.email"
                                    name="email"
                                    class="form-control"
                                />
                            </div>
                            <div class="form-group">
                                <label>Jelszó</label>
                                <input
                                    type="password"
                                    v-model="form.password"
                                    name="password"
                                    class="form-control"
                                />
                            </div>

                            <button
                                type="submit"
                                class="btn w-100 bt-orange add-to-cart-btn"
                            >
                                Bejelentkezés
                            </button>

                            <div class="row my-4">
                                <div class="col-md-6" style="color: white">
                                    <router-link
                                        :to="{ name: 'Registration' }"
                                        style="color:white"
                                        >Vissza a regisztrációhoz</router-link
                                    >
                                </div>
                                <div
                                    class="col-md-6 text-end"
                                    style="text-align: right; color: white"
                                >
                                    <router-link
                                        style="color: white"
                                        :to="{ name: 'ForgetPassword' }"
                                        >Elfelejtett jelszó</router-link
                                    >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "Login",
    data() {
        return {
            form: {
                email: null,
                password: null
            }
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_URL;
        }
    },
    methods: {
        doLogin() {
            this.$http.post(`${this.url}/login`, this.form).then(response => {
                $cookies.set("id_token", response.data.token, 86400);
                $cookies.set("user", response.data, 86400);

                document.location.href = "/";
            });
        }
    }
};
</script>

<style scoped></style>
